<template>
  <b-container class="m-0 w-100" fluid>
    <b-card v-for="(item, index) in dob" :key="index">
      <div class="d-flex justify-content-start"></div>
      <b-row class="pt-1">
        <b-col lg="5">
          <b-form-group label="Message in Spanish" label-for="textarea-1">
            <div
              :class="
                item.isRequiredEs
                  ? ''
                  : isDarkSkin
                  ? ' rounded border-danger'
                  : 'rounded border-danger'
              "
            >
              <skeleton-card :is-busy="item.isBusy" />
              <b-form-textarea
                v-if="!item.isBusy"
                id="textarea-1"
                v-model="item.msg_es"
                placeholder="Write a message..."
                @input="changeMessage(item, 1)"
                :disabled="item.toUpdate"
                rows="3"
                max-rows="6"
                style="height: 160px; max-height: 200px"
              />
            </div>
          </b-form-group>
        </b-col>
        <b-col lg="5">
          <b-form-group label="Message in English" label-for="textarea-2">
            <div
              :class="
                item.isRequiredEn
                  ? ''
                  : isDarkSkin
                  ? ' rounded border-danger'
                  : 'rounded border-danger'
              "
            >
              <skeleton-card :is-busy="item.isBusy" />
              <b-form-textarea
                v-if="!item.isBusy"
                id="textarea-2"
                v-model="item.msg_en"
                placeholder="Write a message..."
                @input="changeMessage(item, 2)"
                :disabled="item.toUpdate"
                rows="3"
                max-rows="6"
                style="height: 160px; max-height: 200px"
              ></b-form-textarea>
            </div>
          </b-form-group>
        </b-col>
        <b-col lg="2">
          <b-row class="d-flex justify-content-center">
            <p style="font-weight: bold">Legend</p>
          </b-row>
          <b-row>
            <b-input-group variant="outline-secondary" size="lg" prepend="@1">
              <b-form-input
                trim
                disabled
                placeholder="FIRST NAME"
              ></b-form-input>
            </b-input-group>

            <b-input-group size="lg" prepend="@2" class="mt-1">
              <b-form-input
                trim
                disabled
                placeholder="LAST NAME"
              ></b-form-input>
            </b-input-group>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="10" class="text-center">
          <div class="d-flex justify-content-center">
            <b-button
              v-if="item.toUpdate"
              variant="warning"
              @click="item.toUpdate = false"
              class="mr-1"
            >
              <feather-icon icon="Edit3Icon"> </feather-icon>
              Update</b-button
            >
            <div v-else>
              <b-button variant="info" @click="reset(item)" class="mr-1">
                <feather-icon icon="Trash2Icon"> </feather-icon>
                Reset</b-button
              >
              <b-button
                variant="primary"
                :disabled="!item.msg_es || !item.msg_en"
                @click="save()"
              >
                <feather-icon icon="SaveIcon"> </feather-icon>
                SAVE</b-button
              >
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
// import MessageService from "@/views/ce-digital/sub-modules/settings/views/messages/service/ce-set-messages.service";
import SkeletonCard from "@/views/ce-digital/sub-modules/settings/views/messages/components/welcome-message/SkeletonCard.vue";
import SettingsService from "@/views/commons/components/settings/services/Settings.service.js";

export default {
  components: { SkeletonCard },
  data() {
    return {
      isBusy: false,
      program: 3,
      dob: [
        {
          id: 1,
          title: "Welcome message",
          msg_es: null,
          msg_en: null,
          isRequiredEs: true,
          isRequiredEn: true,
          toUpdate: true,
          isBusy: true,
        },
      ],
      temps: {
        msg_es: null,
        msg_en: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  mounted() {
    console.log("entro");
    this.getSetting();
  },
  methods: {
    async getSetting() {
      try {
        const body = {
          program: this.program,
          type: 0,
        };
        const res = await SettingsService.getSetting(body);
        this.dob[0].msg_es = res.data[0].msg_es;
        this.dob[0].msg_en = res.data[0].msg_en;
        this.temps.msg_es = res.data[0].msg_es;
        this.temps.msg_en = res.data[0].msg_en;
        setTimeout(() => {
          this.dob[0].isBusy = false;
        }, 900);
      } catch (error) {
        console.log(error);
      }
    },
    reset(item) {
      this.dob[item.id - 1].msg_es = this.temps.msg_es;
      this.dob[item.id - 1].msg_en = this.temps.msg_en;
    },
    async changeMessage(item, type) {
      if (type == 1) {
        if (item.msg_es) {
          this.$set(item, "isRequiredEs", true);
        } else {
          this.$set(item, "isRequiredEs", false);
        }
      }
    },
    async save() {
      try {
        const body = {
          msges: this.dob[0].msg_es,
          msgen: this.dob[0].msg_en,
          program: this.program,
          type: 1,
        };
        const response = await this.showConfirmSwal();
        if (response.isConfirmed) {
          const res = await SettingsService.getSetting(body);
          if (res.status === 200) {
            this.temps.msg_es = JSON.parse(JSON.stringify(this.dob[0].msg_es));
            this.temps.msg_en = JSON.parse(JSON.stringify(this.dob[0].msg_en));
            this.dob[0].msg_es = res.data[0].msg_es;
            this.dob[0].msg_en = res.data[0].msg_en;
            this.dob[0].toUpdate = true;
            this.dob[0].isRequiredEs = true;
            this.dob[0].isRequiredEn = true;
            this.showToast(
              "success",
              "top-right",
              "Success!",
              "CheckIcon",
              "Message created successfully"
            );
            this.removePreloader();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.iconPadding {
  padding: 2px 0px 0px 10px;
}
.cardPadding {
  padding: 16px 0 8px 10px;
}
</style>
